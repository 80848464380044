(async () => {
    var scripts = document.documentElement.querySelectorAll("script");
    var plasticPath = "";
    for (let i = 0; i < scripts.length; i++){
        let script = scripts[i];
        let src = script.getAttribute("src");
        if (typeof src === "undefined" || src === null) continue;
        if (src.indexOf("App/bootstrap") >= 0){
            plasticPath = src.split("App/bootstrap")[0];
        }
    }
    await createScriptTag("https://cdnjs.cloudflare.com/ajax/libs/babel-polyfill/7.4.0/polyfill.js");
    await createScriptTag(`${plasticPath}Load.min.js`, {
        "plastic-load-path": plasticPath,
        "plastic-load": "App"
    });

    function createScriptTag(src, attributes){
        return new Promise(resolve => {
            var script      = document.createElement('script');
            if (typeof attributes !== "undefined"){
                var keys = Object.keys(attributes);
                for (let i = 0; i < keys.length; i++){
                    let attribute = keys[i];
                    let value = attributes[attribute];
                    script.setAttribute(attribute, value);
                }
            }

            script.src      = src;
            script.onload   = resolve;
            if (document.body === null) document.head.appendChild(script); // Pre-body load
            else document.body.appendChild(script);
        });
    }
})()